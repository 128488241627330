import SlickSlider from "../components/slick";

jQuery(function () {
    initScrollEvent();
});

function initScrollEvent() {
    $(window).on('scroll', function (event) {
        var scroll = $(window).scrollTop();
        var headerHeight = $('#header').height();
        if (scroll > headerHeight) {
            $('body').addClass('scrolling');
        } else {
            $('body').removeClass('scrolling');
        }
    });

    $(document).on('click', '.go-to-anchor[href]', function (e) {
        e.preventDefault();
        var selector = $(this).attr('href');
        if ($(selector).length > 0) {
            $('body,html').animate({
                scrollTop: $(selector).position().top
            }, 600);
        }
        return;
    });
}

//SEARCHBOX
$(".xy-research").click(function (e) {
    e.preventDefault();
    $('.searchbox-container').fadeIn();
    $('input[name="search_query"]').focus();
});

$(".searchbox-closebtn").click(function (e) {
    $('.searchbox-container').fadeOut();
});

$('#back-to-top').click(function (e) {
    $('body,html').animate({
        scrollTop: 0
    }, 600);
});

$(document).ready(function () {
    let slickSlider = new SlickSlider();
    slickSlider.init();

    $('.popover-dismiss').popover({
        trigger: 'focus'
    })
});

$(document).ajaxComplete(function () {
    $('.popover-dismiss').popover({
        trigger: 'focus'
    })
})

prestashop.on('updatedProduct', function (event) {
    console.log(event);
});